// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Track from "../utils/Track.mjs";
import * as React from "react";
import * as Images from "../assets/Images.mjs";
import * as LoginForm from "./LoginForm.mjs";
import * as LoginModalUtils from "./LoginModalUtils.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as ForgotPasswordForm from "./ForgotPasswordForm.mjs";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.mjs";
import * as Login_LoginMutation_graphql from "../__generated__/Login_LoginMutation_graphql.mjs";

var convertVariables = Login_LoginMutation_graphql.Internal.convertVariables;

var convertResponse = Login_LoginMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = Login_LoginMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, Login_LoginMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, Login_LoginMutation_graphql.node, convertResponse, convertWrapRawResponse);

var LoginMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function reducer(state, action) {
  if (typeof action !== "object") {
    return {
            username: "",
            password: "",
            error: undefined,
            view: "LoginForm"
          };
  }
  switch (action.TAG) {
    case "SetUsername" :
        return {
                username: action._0,
                password: state.password,
                error: state.error,
                view: state.view
              };
    case "SetPassword" :
        return {
                username: state.username,
                password: action._0,
                error: state.error,
                view: state.view
              };
    case "SetError" :
        return {
                username: state.username,
                password: state.password,
                error: action._0,
                view: state.view
              };
    case "SetView" :
        return {
                username: state.username,
                password: state.password,
                error: state.error,
                view: action._0
              };
    
  }
}

function Login(props) {
  var __logoLocation = props.logoLocation;
  var onDone = props.onDone;
  var logoLocation = __logoLocation !== undefined ? __logoLocation : "None";
  var match = React.useReducer(reducer, {
        username: "",
        password: "",
        error: undefined,
        view: "LoginForm"
      });
  var dispatch = match[1];
  var state = match[0];
  var match$1 = use();
  var login = match$1[0];
  var match$2 = Track.useTrackEvent("Login");
  var trackEvent = match$2.trackEvent;
  var doLogin = React.useCallback((function () {
          dispatch({
                TAG: "SetError",
                _0: undefined
              });
          trackEvent("attemptLogin", undefined);
          login({
                password: state.password,
                username: state.username
              }, undefined, undefined, (function (store, param) {
                  store.invalidateStore();
                }), (function (res, errors) {
                  var match = res.login;
                  if (match !== undefined && match.success) {
                    LoginModalUtils.Notifier.onLoginDone("LoggedIn");
                    dispatch("Reset");
                    onDone();
                    return trackEvent("loginSuccess", undefined);
                  }
                  if (errors === undefined) {
                    return ;
                  }
                  LoginModalUtils.Notifier.onLoginDone("Error");
                  var error = errors[0];
                  if (error !== undefined) {
                    trackEvent("loginFailed", undefined);
                    return dispatch({
                                TAG: "SetError",
                                _0: error.message
                              });
                  }
                  
                }), (function (error) {
                  trackEvent("loginRequestFailed", undefined);
                  dispatch({
                        TAG: "SetError",
                        _0: error.message
                      });
                }), undefined);
        }), [
        login,
        state.username,
        state.password,
        dispatch,
        onDone,
        trackEvent
      ]);
  var tmp;
  tmp = logoLocation === "Top" ? JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx("img", {
                className: "h-24",
                src: Images.logotype
              }),
          className: "mb-12 flex justify-center"
        }) : null;
  var match$3 = state.view;
  var tmp$1;
  tmp$1 = match$3 === "LoginForm" ? JsxRuntime.jsx(LoginForm.make, {
          onSubmit: (function () {
              doLogin();
            }),
          username: state.username,
          onChangeUsername: (function (u) {
              dispatch({
                    TAG: "SetUsername",
                    _0: u
                  });
            }),
          password: state.password,
          onChangePassword: (function (p) {
              dispatch({
                    TAG: "SetPassword",
                    _0: p
                  });
            }),
          loading: match$1[1],
          formError: state.error,
          onForgotPassword: (function () {
              dispatch({
                    TAG: "SetView",
                    _0: "ForgotPassword"
                  });
            })
        }) : JsxRuntime.jsx(ForgotPasswordForm.make, {
          onGoBack: (function () {
              dispatch({
                    TAG: "SetView",
                    _0: "LoginForm"
                  });
            })
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                tmp,
                tmp$1
              ]
            });
}

var make = Login;

export {
  LoginMutation ,
  reducer ,
  make ,
}
/* commitMutation Not a pure module */
