// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Icons from "../Icons.mjs";
import * as React from "react";
import * as Styles from "../utils/Styles.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter__Link from "rescript-relay-router/src/RelayRouter__Link.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function isDisabled(disabled) {
  if (disabled !== undefined && disabled._0) {
    return true;
  } else {
    return false;
  }
}

function isDisabledVisibly(disabled) {
  if (disabled !== undefined && !(disabled.TAG === "Hidden" || !disabled._0)) {
    return true;
  } else {
    return false;
  }
}

function useOneShotValidityState(timeoutOpt) {
  var timeout = timeoutOpt !== undefined ? timeoutOpt : 5000;
  var match = React.useState(function () {
        
      });
  var setValidationState = match[1];
  var validationState = match[0];
  React.useEffect((function () {
          if (validationState === undefined) {
            return ;
          }
          var timeout$1 = setTimeout((function () {
                  setValidationState(function (param) {
                        
                      });
                }), timeout);
          return (function () {
                    clearTimeout(timeout$1);
                  });
        }), [
        timeout,
        validationState
      ]);
  return {
          oneShotValidityState: validationState,
          setOneShotValidityState: React.useCallback((function (state) {
                  setValidationState(function (param) {
                        return state;
                      });
                }), [setValidationState])
        };
}

function Button(props) {
  var __className = props.className;
  var oneShotSetShowState = props.oneShotSetShowState;
  var title = props.title;
  var variant = props.variant;
  var __size = props.size;
  var layout = props.layout;
  var disabled = props.disabled;
  var rightIcon = props.rightIcon;
  var leftIcon = props.leftIcon;
  var __resultCooldown = props.resultCooldown;
  var onClickWithResult = props.onClickWithResult;
  var onClick = props.onClick;
  var href = props.href;
  var loading = props.loading;
  var typ = props.typ;
  var resultCooldown = __resultCooldown !== undefined ? __resultCooldown : 5000;
  var size = __size !== undefined ? __size : "Default";
  var className = __className !== undefined ? __className : "";
  var match = React.useState(function () {
        return "Idle";
      });
  var setShowState = match[1];
  var showState = match[0];
  React.useEffect((function () {
          if (showState === "Idle") {
            return ;
          }
          var timeout = setTimeout((function () {
                  setShowState(function (param) {
                        return "Idle";
                      });
                }), resultCooldown);
          return (function () {
                    clearTimeout(timeout);
                  });
        }), [
        resultCooldown,
        showState
      ]);
  React.useEffect((function () {
          if (oneShotSetShowState !== undefined) {
            setShowState(function (param) {
                  return oneShotSetShowState;
                });
          }
          
        }), [oneShotSetShowState]);
  var tmp;
  tmp = size === "Small" ? "py-1 px-2 text-sm" : "py-2 px-4 text-sm";
  var tmp$1;
  switch (showState) {
    case "Idle" :
        if (isDisabledVisibly(disabled)) {
          tmp$1 = "text-gray-600 focus:ring-gray-800 bg-gray-300";
        } else if (variant !== undefined) {
          switch (variant) {
            case "Primary" :
                tmp$1 = U.tw([
                      "text-white focus:ring-gray-700",
                      Styles.standardGradientBackground
                    ]);
                break;
            case "Outlined" :
                tmp$1 = U.tw(["text-gray-700 !border-gray-500 focus:ring-gray-700"]);
                break;
            case "Destructive" :
                tmp$1 = U.tw([
                      "text-white !border-red-600 focus:ring-red-700",
                      "bg-red-600"
                    ]);
                break;
            
          }
        } else {
          tmp$1 = U.tw([
                "text-white focus:ring-gray-700",
                Styles.standardGradientBackground
              ]);
        }
        break;
    case "Success" :
        tmp$1 = "text-white focus:ring-green-500 bg-green-600 hover:bg-green-700";
        break;
    case "Warning" :
        tmp$1 = "text-white focus:ring-yellow-500 bg-yellow-600 hover:bg-yellow-700";
        break;
    case "Error" :
        tmp$1 = "text-white focus:ring-red-500 bg-red-600 hover:bg-red-700";
        break;
    
  }
  var tmp$2;
  if (layout !== undefined) {
    switch (layout) {
      case "FullWidth" :
          tmp$2 = "w-full";
          break;
      case "Static" :
          tmp$2 = "w-72 max-w-full";
          break;
      case "Fluid" :
          tmp$2 = "w-auto";
          break;
      
    }
  } else {
    tmp$2 = "w-auto";
  }
  var styles = U.tw([
        "flex justify-center border-2 border-transparent items-center",
        "rounded-md shadow-md font-medium",
        "focus:outline-none focus:ring-2 focus:ring-offset-2",
        tmp,
        isDisabled(disabled) ? "cursor-not-allowed" : "",
        tmp$1,
        tmp$2,
        className
      ]);
  var tmp$3;
  var exit = 0;
  if (loading !== undefined && loading) {
    tmp$3 = JsxRuntime.jsx("span", {
          children: Icons.spinner(U.tw([
                    "animate-spin h-5 w-5",
                    isDisabledVisibly(disabled) ? "text-gray-500" : "text-white"
                  ])),
          className: "-ml-1 mr-3"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$3 = leftIcon !== undefined ? JsxRuntime.jsx("span", {
            children: Caml_option.valFromOption(leftIcon),
            className: "-ml-1 mr-2"
          }, "loading") : null;
  }
  var tmp$4;
  if (showState === "Idle") {
    tmp$4 = rightIcon !== undefined ? JsxRuntime.jsx("span", {
            children: Caml_option.valFromOption(rightIcon),
            className: "-mr-1 ml-2"
          }, "show-state") : null;
  } else {
    var tmp$5;
    switch (showState) {
      case "Idle" :
          tmp$5 = null;
          break;
      case "Success" :
          tmp$5 = Icons.check("h-5 w-5 text-green-300");
          break;
      case "Warning" :
          tmp$5 = Icons.exclamationCircle("h-5 w-5 text-yellow-300");
          break;
      case "Error" :
          tmp$5 = Icons.exclamationCircle("h-5 w-5 text-red-300");
          break;
      
    }
    tmp$4 = JsxRuntime.jsx("span", {
          children: tmp$5,
          className: "-mr-1 ml-2"
        }, "show-state");
  }
  var content = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          tmp$3,
          JsxRuntime.jsx("span", {
                children: JsxRuntime.jsx("span", {
                      children: props.text
                    })
              }),
          tmp$4
        ]
      });
  if (href !== undefined) {
    return JsxRuntime.jsx(RelayRouter__Link.make, {
                to_: href,
                title: title,
                className: U.tw([
                      styles,
                      "inline-flex"
                    ]),
                children: content,
                onClick: onClick
              });
  }
  var tmp$6;
  tmp$6 = typ === "Submit" ? "submit" : "button";
  return JsxRuntime.jsx("button", {
              children: content,
              className: styles,
              title: title,
              disabled: isDisabled(disabled),
              type: tmp$6,
              onClick: (function (e) {
                  if (onClick !== undefined) {
                    if (onClickWithResult === undefined) {
                      return onClick();
                    }
                    
                  } else if (onClickWithResult === undefined) {
                    return ;
                  }
                  onClickWithResult(e, (function (showState) {
                          setShowState(function (param) {
                                return showState;
                              });
                        }));
                })
            });
}

var make = Button;

export {
  isDisabled ,
  isDisabledVisibly ,
  useOneShotValidityState ,
  make ,
}
/* Icons Not a pure module */
