// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.mjs";
import * as UserContextDataFetcherQuery_graphql from "../__generated__/UserContextDataFetcherQuery_graphql.mjs";

var convertVariables = UserContextDataFetcherQuery_graphql.Internal.convertVariables;

var convertResponse = UserContextDataFetcherQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = UserContextDataFetcherQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, UserContextDataFetcherQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, UserContextDataFetcherQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(UserContextDataFetcherQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(UserContextDataFetcherQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(UserContextDataFetcherQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(UserContextDataFetcherQuery_graphql.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function UserContextDataFetcher(props) {
  use(undefined, undefined, undefined, undefined);
  try {
    
  }
  catch (exn){
    
  }
  return null;
}

var make = UserContextDataFetcher;

export {
  Query ,
  make ,
}
/* use Not a pure module */
