// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Track from "./utils/Track.mjs";
import * as React from "react";
import * as RelayRouter__Utils from "rescript-relay-router/src/RelayRouter__Utils.mjs";

function PageViewTracker(props) {
  var match = Track.useTrackEvent("PageView");
  var trackEvent = match.trackEvent;
  var $$location = RelayRouter__Utils.useLocation();
  React.useEffect((function () {
          trackEvent(undefined, undefined);
        }), [$$location.pathname]);
  return null;
}

var make = PageViewTracker;

export {
  make ,
}
/* Track Not a pure module */
