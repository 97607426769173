// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Card from "./Card.mjs";
import * as $$Text from "./Text.mjs";
import * as Icons from "../Icons.mjs";
import * as Input from "./Input.mjs";
import * as Track from "../utils/Track.mjs";
import * as React from "react";
import * as Button from "./Button.mjs";
import * as PageTitle from "../layout/PageTitle.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as HandleMutationErrors from "./HandleMutationErrors.mjs";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.mjs";
import * as ForgotPasswordForm_ForgotPasswordMutation_graphql from "../__generated__/ForgotPasswordForm_ForgotPasswordMutation_graphql.mjs";

var convertVariables = ForgotPasswordForm_ForgotPasswordMutation_graphql.Internal.convertVariables;

var convertResponse = ForgotPasswordForm_ForgotPasswordMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = ForgotPasswordForm_ForgotPasswordMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, ForgotPasswordForm_ForgotPasswordMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, ForgotPasswordForm_ForgotPasswordMutation_graphql.node, convertResponse, convertWrapRawResponse);

var ForgotPasswordMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function canSubmit(email) {
  return email.length > 3;
}

function ForgotPasswordForm(props) {
  var onGoBack = props.onGoBack;
  var match = React.useState(function () {
        return "";
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return "Form";
      });
  var setViewState = match$1[1];
  var match$2 = use();
  var isPostingForgotPassword = match$2[1];
  var forgotPassword = match$2[0];
  var match$3 = Track.useTrackEvent("ForgotPassword");
  var trackEvent = match$3.trackEvent;
  var match$4 = HandleMutationErrors.use();
  var handleSingleError = match$4[1];
  var handleErrors = match$4[0];
  var onSubmit = function () {
    if (email.length > 3) {
      trackEvent("forgotPassword", undefined);
      forgotPassword({
            email: email
          }, undefined, undefined, undefined, (function (res, maybeErrors) {
              if (!handleErrors(maybeErrors, res.forgotPassword.fragmentRefs) && res.forgotPassword.success) {
                return setViewState(function (param) {
                            return "Done";
                          });
              }
              
            }), handleSingleError, undefined);
      return ;
    }
    
  };
  if (match$1[0] === "Form") {
    return JsxRuntime.jsxs("form", {
                children: [
                  JsxRuntime.jsx("a", {
                        children: Icons.leftArrow("w-4 h-4"),
                        title: "Go back",
                        href: "#",
                        onClick: (function (param) {
                            onGoBack();
                          })
                      }),
                  JsxRuntime.jsx(PageTitle.make, {
                        title: "Glömt ditt lösenord?",
                        subContent: Caml_option.some(JsxRuntime.jsx($$Text.Descriptive.make, {
                                  text: "Skriv in din e-postadress nedan så skickar vi ett mail med instruktioner för hur du återställer ditt lösenord."
                                }))
                      }),
                  JsxRuntime.jsx(Card.make, {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Input.make, {
                                            name: "email",
                                            label: "Skriv din e-postadress",
                                            value: email,
                                            typ: "Email",
                                            placeholder: "E-mail",
                                            autoComplete: "email",
                                            onChangeText: (function (newText) {
                                                setEmail(function (param) {
                                                      return newText.trim();
                                                    });
                                              }),
                                            leadingAddon: {
                                              TAG: "Icon",
                                              _0: Icons.lock("text-gray-500 w-5 h-5")
                                            }
                                          })
                                    }),
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Button.make, {
                                            text: "Skicka återställningsmail",
                                            typ: "Submit",
                                            loading: isPostingForgotPassword,
                                            leftIcon: Caml_option.some(Icons.mail(undefined)),
                                            disabled: {
                                              TAG: "Visible",
                                              _0: email.length <= 3 || isPostingForgotPassword
                                            },
                                            layout: "FullWidth"
                                          })
                                    })
                              ],
                              className: "flex flex-col space-y-4"
                            })
                      })
                ],
                className: "space-y-6",
                action: "POST",
                onSubmit: (function (e) {
                    e.preventDefault();
                    onSubmit();
                  })
              });
  } else {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx($$Text.LargeTitle.make, {
                              title: "Sådär!"
                            }),
                        className: ""
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx($$Text.Descriptive.make, {
                              text: "Du borde få ett e-mail inom kort med en länk du kan trycka på för att återställa ditt lösenord. Kom ihåg att det kan ta flera minuter innan e-mailet kommer fram."
                            }),
                        className: ""
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Button.make, {
                              text: "Tillbaka",
                              typ: "Button",
                              onClick: (function () {
                                  onGoBack();
                                }),
                              layout: "FullWidth"
                            })
                      })
                ],
                className: ""
              });
  }
}

var make = ForgotPasswordForm;

export {
  ForgotPasswordMutation ,
  canSubmit ,
  make ,
}
/* commitMutation Not a pure module */
