// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as RouterUtils from "./RouterUtils.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as React$1 from "posthog-js/react";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as TrackQuery_graphql from "../__generated__/TrackQuery_graphql.mjs";
import * as Track_user_graphql from "../__generated__/Track_user_graphql.mjs";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.mjs";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.mjs";

var convertVariables = TrackQuery_graphql.Internal.convertVariables;

var convertResponse = TrackQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = TrackQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, TrackQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, TrackQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(TrackQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(TrackQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(TrackQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(TrackQuery_graphql.node, convertVariables);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertFragment = Track_user_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, Track_user_graphql.node, convertFragment, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(Track_user_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, Track_user_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(Track_user_graphql.node, convertFragment, fRef);
}

var UserFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

var hasIdentifiedUser = {
  contents: false
};

function useTrackEvent(eventName) {
  var postHog = React$1.usePostHog();
  var orgSlug = RouterUtils.useCurrentOrganizationSlugOpt();
  var router = RelayRouter.useRouterContext();
  var user = use(undefined, "store-only", undefined, undefined);
  var isAdmin;
  try {
    isAdmin = user.me.isAdmin;
  }
  catch (exn){
    isAdmin = undefined;
  }
  var userId;
  try {
    userId = user.me.dbId.slice(0, 16);
  }
  catch (exn$1){
    userId = undefined;
  }
  React.useEffect((function () {
          var match = hasIdentifiedUser.contents;
          if (match) {
            if (userId !== undefined) {
              
            } else {
              hasIdentifiedUser.contents = false;
              if (postHog.__loaded) {
                postHog.reset();
              } else {
                console.log("=== RESETTING IDENTIFY ===");
              }
            }
          } else if (userId !== undefined) {
            hasIdentifiedUser.contents = true;
            var properties = Object.fromEntries([[
                    "isSuperAdmin",
                    Core__Option.getOr(Core__Option.map(isAdmin, (function (v) {
                                return v;
                              })), undefined)
                  ]]);
            if (postHog.__loaded) {
              postHog.identify(userId, properties);
            } else {
              console.log("=== IDENTIFYING USER ===", userId, properties);
            }
          }
          
        }), [userId]);
  var trackEvent = React.useMemo((function () {
          return function (subEvent, data) {
            try {
              var routeKey = Core__Option.flatMap(router.get().preparedMatches.at(-1), (function (p) {
                      return p.routeKey.split(":")[0];
                    }));
              var data$1 = Object.fromEntries(Belt_Array.concatMany([
                        [
                          [
                            "route",
                            Core__Option.getOr(Core__Option.map(routeKey, (function (v) {
                                        return v;
                                      })), undefined)
                          ],
                          [
                            "organization",
                            Core__Option.getOr(Core__Option.map(orgSlug, (function (v) {
                                        return v;
                                      })), undefined)
                          ],
                          [
                            "userId",
                            Core__Option.getOr(Core__Option.map(userId, (function (v) {
                                        return v;
                                      })), undefined)
                          ],
                          [
                            "isSuperAdmin",
                            Core__Option.getOr(Core__Option.map(isAdmin, (function (v) {
                                        return v;
                                      })), undefined)
                          ],
                          [
                            "$groups",
                            orgSlug !== undefined ? Object.fromEntries([[
                                      "organization",
                                      orgSlug
                                    ]]) : undefined
                          ]
                        ],
                        data !== undefined ? Object.entries(data) : []
                      ]));
              var eventName$1 = eventName + (
                subEvent !== undefined ? " - " + subEvent : ""
              );
              if (postHog.__loaded) {
                if (orgSlug !== undefined) {
                  postHog.group("organization", orgSlug, Object.fromEntries([[
                              "slug",
                              orgSlug
                            ]]));
                } else {
                  postHog.resetGroup();
                }
                postHog.capture(eventName$1, data$1);
              } else {
                console.log("=== TRACKING EVENT ===", eventName$1, data$1);
              }
              return ;
            }
            catch (raw_e){
              var e = Caml_js_exceptions.internalToOCamlException(raw_e);
              console.error("Tracking failed", e);
              return ;
            }
          };
        }), [
        postHog,
        orgSlug,
        router,
        eventName,
        isAdmin
      ]);
  return {
          trackEvent: trackEvent
        };
}

export {
  Query ,
  UserFragment ,
  hasIdentifiedUser ,
  useTrackEvent ,
}
/* use Not a pure module */
