// Generated by ReScript, PLEASE EDIT WITH CARE

import * as PosthogJs from "posthog-js";

var Client = {};

var Provider = {};

var client = PosthogJs.default.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
      api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
      debug: import.meta.env.NODE_ENV === "development",
      autocapture: false,
      enable_recording: false,
      capture_pageview: false,
      capture_pageleave: false,
      disable_session_recording: true
    });

export {
  Client ,
  Provider ,
  client ,
}
/* client Not a pure module */
