// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Icons from "../Icons.mjs";
import * as Styles from "../utils/Styles.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Input(props) {
  var validationState = props.validationState;
  var trailingAddon = props.trailingAddon;
  var leadingAddon = props.leadingAddon;
  var hint = props.hint;
  var helpText = props.helpText;
  var onChangeText = props.onChangeText;
  var onChange = props.onChange;
  var __required = props.required;
  var typ = props.typ;
  var hideLabel = props.hideLabel;
  var name = props.name;
  var required = __required !== undefined ? __required : false;
  var tmp;
  if (leadingAddon !== undefined) {
    var tmp$1;
    tmp$1 = leadingAddon.TAG === "Txt" ? JsxRuntime.jsx("span", {
            children: leadingAddon._0,
            className: "text-gray-500 sm:text-sm"
          }) : leadingAddon._0;
    tmp = JsxRuntime.jsx("div", {
          children: tmp$1,
          className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        });
  } else {
    tmp = null;
  }
  var tmp$2;
  if (validationState !== undefined) {
    var tmp$3;
    switch (validationState.TAG) {
      case "Error" :
          tmp$3 = Icons.exclamationCircle("h-5 w-5 text-red-500");
          break;
      case "Warning" :
          tmp$3 = Icons.exclamationCircle("h-5 w-5 text-yellow-500");
          break;
      case "Success" :
          tmp$3 = Icons.check("h-5 w-5 text-green-500");
          break;
      
    }
    tmp$2 = JsxRuntime.jsx("div", {
          children: tmp$3,
          className: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        });
  } else if (trailingAddon !== undefined) {
    var tmp$4;
    tmp$4 = trailingAddon.TAG === "Txt" ? JsxRuntime.jsx("span", {
            children: trailingAddon._0,
            className: "text-gray-500 sm:text-sm"
          }) : trailingAddon._0;
    tmp$2 = JsxRuntime.jsx("div", {
          children: tmp$4,
          className: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
        });
  } else {
    tmp$2 = null;
  }
  var tmp$5;
  if (validationState !== undefined) {
    switch (validationState.TAG) {
      case "Error" :
          tmp$5 = "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";
          break;
      case "Warning" :
          tmp$5 = "border-yellow-300 text-yellow-900 placeholder-yellow-300 focus:ring-yellow-500 focus:border-yellow-500";
          break;
      case "Success" :
          tmp$5 = "border-green-300 text-green-900 placeholder-green-400 focus:ring-green-500 focus:border-green-500";
          break;
      
    }
  } else {
    tmp$5 = "focus:ring-gray-500 focus:border-gray-500 border-gray-300";
  }
  var tmp$6;
  if (typ !== undefined) {
    switch (typ) {
      case "Email" :
          tmp$6 = "email";
          break;
      case "Password" :
          tmp$6 = "password";
          break;
      case "Number" :
          tmp$6 = "number";
          break;
      case "Text" :
          tmp$6 = "text";
          break;
      case "URL" :
          tmp$6 = "url";
          break;
      
    }
  } else {
    tmp$6 = "text";
  }
  var tmp$7;
  if (validationState !== undefined) {
    var tmp$8;
    switch (validationState.TAG) {
      case "Error" :
          tmp$8 = "text-red-500";
          break;
      case "Warning" :
          tmp$8 = "text-yellow-500";
          break;
      case "Success" :
          tmp$8 = "text-green-500";
          break;
      
    }
    tmp$7 = JsxRuntime.jsx("p", {
          children: validationState._0,
          className: U.tw([
                "mt-2 text-sm",
                tmp$8
              ]),
          id: name + "-validation"
        });
  } else {
    tmp$7 = helpText !== undefined ? JsxRuntime.jsx("p", {
            children: helpText,
            className: "mt-2 text-sm text-gray-500",
            id: name + "-description"
          }) : null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("label", {
                              children: props.label,
                              className: hideLabel !== undefined && hideLabel ? "sr-only" : Styles.standardLabelClasses,
                              htmlFor: name
                            }),
                        hint !== undefined ? JsxRuntime.jsx("span", {
                                children: hint,
                                className: "text-sm text-gray-500",
                                id: name + "-optional"
                              }) : null
                      ],
                      className: "flex justify-between"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp,
                        tmp$2,
                        JsxRuntime.jsx("input", {
                              defaultValue: props.defaultValue,
                              className: U.tw([
                                    "block w-full sm:text-sm rounded-md",
                                    "disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-500",
                                    tmp$5,
                                    leadingAddon !== undefined ? "pl-10" : "",
                                    trailingAddon !== undefined ? "pr-10" : ""
                                  ]),
                              id: name,
                              autoComplete: props.autoComplete,
                              autoFocus: props.autoFocus,
                              disabled: props.disabled,
                              max: props.max,
                              maxLength: props.maxLength,
                              min: props.min,
                              name: name,
                              placeholder: props.placeholder,
                              required: required,
                              step: props.step,
                              type: tmp$6,
                              value: props.value,
                              onFocus: props.onFocus,
                              onBlur: props.onBlur,
                              onChange: (function (e) {
                                  if (onChange !== undefined) {
                                    onChange(e);
                                  }
                                  if (onChangeText === undefined) {
                                    return ;
                                  }
                                  var value = e.currentTarget.value;
                                  onChangeText(value);
                                })
                            })
                      ],
                      className: "mt-1 relative rounded-md shadow-sm"
                    }),
                tmp$7
              ]
            });
}

var make = Input;

export {
  make ,
}
/* Icons Not a pure module */
