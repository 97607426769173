// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.mjs";
import * as React from "react";
import * as Router from "./routes/Router.mjs";
import * as Recoil from "recoil";
import * as PostHog from "./bindings/PostHog.mjs";
import * as RelayEnv from "./RelayEnv.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as React$1 from "@sentry/react";
import * as ErrorBoundary from "./layout/ErrorBoundary.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as AppInitializers from "./AppInitializers.mjs";
import * as PageViewTracker from "./PageViewTracker.mjs";
import * as React$2 from "posthog-js/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactDOMExperimental from "rescript-relay/src/ReactDOMExperimental.mjs";

import 'requestidlecallback-polyfill'
;

history.scrollRestoration = "manual"
;

var sentryDsn = import.meta.env.VITE_PUBLIC_SENTRY_DSN;

if (sentryDsn === null || sentryDsn === undefined) {
  sentryDsn === null;
} else {
  React$1.init({
        dsn: sentryDsn
      });
}

ReactDOMExperimental.renderConcurrentRootAtElementWithId(JsxRuntime.jsx(React$2.PostHogProvider, {
          client: PostHog.client,
          children: JsxRuntime.jsx(Recoil.RecoilRoot, {
                children: JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
                      environment: RelayEnv.environment,
                      children: JsxRuntime.jsxs(RelayRouter.Provider.make, {
                            value: Router.routerContext,
                            children: [
                              JsxRuntime.jsx(AppInitializers.make, {}),
                              JsxRuntime.jsx(PageViewTracker.make, {}),
                              JsxRuntime.jsx(React.Suspense, {
                                    children: Caml_option.some(JsxRuntime.jsx(ErrorBoundary.make, {
                                              children: JsxRuntime.jsx(App.make, {}),
                                              fallback: (function (param) {
                                                  return "Error!!!!";
                                                })
                                            })),
                                    fallback: "Loading..."
                                  })
                            ]
                          })
                    })
              })
        }), "root");

export {
  
}
/*  Not a pure module */
