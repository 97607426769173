// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GraphQLWs from "../bindings/GraphQLWs.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as RelayRuntime from "relay-runtime";
import * as LoginModalUtils from "../components/LoginModalUtils.mjs";
import FetchMultipartGraphql from "fetch-multipart-graphql";

function needsLogin(parts) {
  var loginNeeded = {
    contents: false
  };
  parts.forEach(function (part) {
        var errors = part.errors;
        if (!(errors == null)) {
          errors.forEach(function (error) {
                var extensions = error.extensions;
                if (extensions == null) {
                  return ;
                }
                var match = extensions.code;
                if (!(match == null)) {
                  loginNeeded.contents = true;
                  return ;
                }
                
              });
          return ;
        }
        
      });
  return loginNeeded.contents;
}

var queriesToIgnoreLoginFor = ["UserContextDataFetcherQuery"];

function fetchQuery(operation, variables, _cacheConfig, _uploads) {
  return RelayRuntime.Observable.create(function (sink) {
              var notLoggedInAndRequestRequiresLogin = {
                contents: false
              };
              var makeThisRequest = function () {
                FetchMultipartGraphql("/api/graphql", {
                      method: "POST",
                      headers: Object.fromEntries([[
                              "content-type",
                              "application/json"
                            ]]),
                      body: Core__Option.getOr(JSON.stringify({
                                query: operation.text,
                                variables: variables
                              }), ""),
                      onNext: (function (parts) {
                          var partsNeedLogin = queriesToIgnoreLoginFor.includes(operation.name) ? false : needsLogin(parts);
                          var match = notLoggedInAndRequestRequiresLogin.contents;
                          if (partsNeedLogin) {
                            if (match) {
                              return ;
                            } else {
                              notLoggedInAndRequestRequiresLogin.contents = true;
                              return LoginModalUtils.Notifier.requestLogin(function (action) {
                                          if (action !== "LoggedIn") {
                                            return ;
                                          }
                                          notLoggedInAndRequestRequiresLogin.contents = false;
                                          makeThisRequest();
                                        });
                            }
                          } else {
                            return sink.next(parts);
                          }
                        }),
                      onError: (function (err) {
                          sink.error(err);
                        }),
                      onComplete: (function () {
                          if (notLoggedInAndRequestRequiresLogin.contents) {
                            return ;
                          } else {
                            return sink.complete();
                          }
                        }),
                      credentials: "same-origin"
                    });
              };
              makeThisRequest();
            });
}

function getCurrentHost() {
  return new URL(window.location.href).host;
}

function getCurrentProtocol() {
  return new URL(window.location.href).protocol;
}

var $$URL$1 = {
  getCurrentHost: getCurrentHost,
  getCurrentProtocol: getCurrentProtocol
};

var match = new URL(window.location.href).protocol;

var subscriptionsClient = GraphQLWs.Client.make(GraphQLWs.Client.makeOptions((
          match === "http:" ? "ws:" : "wss:"
        ) + "//" + new URL(window.location.href).host + "/api/graphql", undefined));

function subscribeFn(operation, variables, _cacheConfig) {
  return RelayRuntime.Observable.create(function (sink) {
              var unsubscribe = GraphQLWs.Client.subscribe(subscriptionsClient, {
                    operationName: operation.name,
                    query: operation.text,
                    variables: variables
                  }, sink);
              return {
                      unsubscribe: unsubscribe,
                      closed: false
                    };
            });
}

export {
  needsLogin ,
  queriesToIgnoreLoginFor ,
  fetchQuery ,
  $$URL$1 as $$URL,
  subscriptionsClient ,
  subscribeFn ,
}
/* subscriptionsClient Not a pure module */
