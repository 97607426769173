// Generated by ReScript, PLEASE EDIT WITH CARE


var hasTriggeredBeforeRegisteredListener = {
  contents: false
};

var onTriggerLogin = {
  contents: undefined
};

function setTriggerLoginCallback(callback) {
  onTriggerLogin.contents = callback;
  if (hasTriggeredBeforeRegisteredListener.contents) {
    callback();
    hasTriggeredBeforeRegisteredListener.contents = false;
    return ;
  }
  
}

function triggerLogin() {
  var callback = onTriggerLogin.contents;
  if (callback !== undefined) {
    return callback();
  } else {
    hasTriggeredBeforeRegisteredListener.contents = true;
    return ;
  }
}

var LoginTrigger = {
  setTriggerLoginCallback: setTriggerLoginCallback,
  triggerLogin: triggerLogin
};

var listeners = {
  contents: []
};

function requestLogin(callback) {
  listeners.contents = [callback].concat(listeners.contents);
  triggerLogin();
}

function onLoginDone(action) {
  listeners.contents.forEach(function (listener) {
        listener(action);
      });
  if (action === "LoggedIn") {
    listeners.contents = [];
    return ;
  }
  
}

var Notifier = {
  requestLogin: requestLogin,
  onLoginDone: onLoginDone
};

export {
  LoginTrigger ,
  Notifier ,
}
/* No side effect */
